<template>
    <transition appear enter-active-class="animated fadeIn">
        <div>
            <div id="header-title" class="level-item">
                <h1 class="title is-1 page-title">POLICY WORDINGS</h1>
            </div>
            <div class="box b-shdw-3">
                <policy-wording/>
            </div>
        </div>
    </transition>
</template>

<script>
    import PolicyWording from "@/components/policy-wordings/PolicyWording";

    export default {
        name: "PolicyWordings",
        components: {
            PolicyWording
        }
    }
</script>

<style lang="scss" scoped>
    h5 {
        color: $c2g_orange;
        margin-bottom: 8px !important;
        text-align: center;
        border-bottom: 3px $c2g_orange solid;
        padding-bottom: 8px;
    }
    #header-title {
        padding: 0 0 40px 0;
    }
</style>